ul {
    list-style-type: disc
}

.row-striped:nth-of-type(odd){
    background-color: #efefef;
 }
  
  .row-striped:nth-of-type(even){
    background-color: #ffffff;
}

.validationError {
    border-color: red;
}

.validationError:focus {
    outline: none !important;
    border:1px solid red;
    box-shadow: 0 0 3px red;
}

.selectedHistoryItem {
    outline: thin solid #0000FF;
}
